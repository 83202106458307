.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "AnovaUIBold";
  src:
    local("AnovaUIBold"),
    url("../node_modules/@sas/filament-fonts/anova-ui/AnovaUI-Bold.woff2")
      format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "AnovaUIBoldItalic";
  src:
    local("AnovaUIBoldItalic"),
    url("../node_modules/@sas/filament-fonts/anova-ui/AnovaUI-BoldItalic.woff2")
      format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "AnovaUIItalic";
  src:
    local("AnovaUIItalic"),
    url("../node_modules/@sas/filament-fonts/anova-ui/AnovaUI-Italic.woff2")
      format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "AnovaUILight";
  src:
    local("AnovaUILight"),
    url("../node_modules/@sas/filament-fonts/anova-ui/AnovaUI-Light.woff2")
      format("truetype");
}

@font-face {
  font-family: "AnovaUILightItalic";
  src:
    local("AnovaUILightItalic"),
    url("../node_modules/@sas/filament-fonts/anova-ui/AnovaUI-LightItalic.woff2")
      format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "AnovaUIRegular";
  src:
    local("AnovaUIRegular"),
    url("../node_modules/@sas/filament-fonts/anova-ui/AnovaUI-Regular.woff2")
      format("truetype");
}

@font-face {
  font-family: "AnovaBold";
  src:
    local("AnovaBold"),
    url("../node_modules/@sas/filament-fonts/anova/Anova-Bold.woff2")
      format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "AnovaBoldItalic";
  src:
    local("AnovaBoldItalic"),
    url("../node_modules/@sas/filament-fonts/anova/Anova-BoldItalic.woff2")
      format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "AnovaItalic";
  src:
    local("AnovaItalic"),
    url("../node_modules/@sas/filament-fonts/anova/Anova-Italic.woff2")
      format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "AnovaLight";
  src:
    local("AnovaLight"),
    url("../node_modules/@sas/filament-fonts/anova/Anova-Light.woff2")
      format("truetype");
}

@font-face {
  font-family: "AnovaLightItalic";
  src:
    local("AnovaLightItalic"),
    url("../node_modules/@sas/filament-fonts/anova/Anova-LightItalic.woff2")
      format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "AnovaRegular";
  src:
    local("AnovaRegular"),
    url("../node_modules/@sas/filament-fonts/anova/Anova-Regular.woff2")
      format("truetype");
}
