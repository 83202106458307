@import "styles/modules/_colors";
@import "styles/modules/_mixins";
@import "styles/modules/_utility";
@import "styles/modules/_variables";

.shareImageContainer {
  float: right;
  height: $space-l-s;
  width: $space-l-s;
  border-radius: 50%;
  border-width: $border-width-1;
  color: $black-6;
  background-color: $grey-13;
  font-size: 14px;

  &:disabled {
    pointer-events: none;
    cursor: default;
    border-color: $grey-35;
    background-color: $grey-13;
  }
  &:hover {
    background-color: $grey-14;
    border-color: $grey-16;
  }
  &:focus {
    background-color: $grey-15;
    border-color: $grey-16;
  }
}
.shareImg {
  vertical-align: top;
  padding-top: 3px;
  padding-right: 2px;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(239deg) brightness(104%)
    contrast(100%);
  &:hover,
  &:focus {
    filter: invert(0%) sepia(3%) saturate(0%) hue-rotate(214deg)
      brightness(104%) contrast(103%);
  }
}
.shareImgDisabled {
  vertical-align: top;
  padding-top: 3px;
  padding-right: 2px;
  filter: invert(50%) sepia(12%) saturate(542%) hue-rotate(178deg)
    brightness(100%) contrast(89%);
}
.popoverBody {
  padding: $space-m $space-m 0 $space-m;
  &:first-child {
    padding-bottom: $space-xs;
  }
  &:last-child {
    padding-top: $space-xs;
    padding-bottom: $space-m;
  }
}
.shareItemUrlCopied {
  padding-left: 1px;
  &:first-child {
    padding-bottom: $space-xs;
  }
  &:last-child {
    padding-top: $space-xs;
  }
}
.shareItemSpan {
  &:first-child {
    padding-bottom: $space-xs;
  }
  &:last-child {
    padding-top: $space-xs;
  }
}
.shareItemButton {
  padding: $space-xs $space-m $space-xs 0;
  width: 100%;
  text-align: left;
  border-width: $border-width-1;
  color: $black-6;
  border-color: $grey-35;
  background-color: $grey-35;

  &:hover {
    background-color: $white-5;
    border-color: $white-5;
    color: $black-6;
  }
  &:focus {
    background-color: $white-6;
    border-color: $white-6;
    color: $blue-35;
  }
}
.shareItemButtonCopied {
  padding: $space-xs $space-m $space-xs 0;
  width: 100%;
  text-align: left;
  background-color: $white-7 !important;
  border-color: $green-1;
  color: $green-2;
}
.shareItemButtonSpan {
  width: $space-m;
  display: inline-block;
}
.urlCopiedImg {
  padding: 3px 2px 2px 2px;
  vertical-align: top;
  filter: invert(44%) sepia(31%) saturate(1020%) hue-rotate(73deg)
    brightness(89%) contrast(84%);
}
.mailtoHref {
  color: $black-6;
  text-decoration: none;
}
