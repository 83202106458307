@import "styles/modules/_colors";
@import "styles/modules/_mixins";
@import "styles/modules/_utility";
@import "styles/modules/_variables";

// Small device filter container
.smallDeviceFilterContainer {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 9;

  .headerDiv {
    background: #f9fafa;
    height: $height-5;
    border-radius: 0;
    border: none;
    padding: 0;
    height: $height-6;
    display: flex;
    align-items: center;

    .header {
      font-size: $text-l-xl;
      font-weight: bold;
      flex-grow: 1;
      margin-left: $space-l;
      font-family: $base-font-family;
    }
    .close {
      color: $dark-color-text-default;
      opacity: 1;
      margin-right: $space-m;
      padding: 0;
      width: $space-xxxl;
      height: $space-xxxl;
      font-size: $text-m;
      border: none;
      border-radius: $border-radius-4;
      background-color: $white-4;

      &:hover,
      &:focus {
        opacity: 1;
      }
      &:hover {
        background-color: $grey-5;
      }
      &:focus {
        background-color: $grey-6;
      }
    }
    svg {
      width: $space-m-xs;
      height: $space-m-xs;
      fill: $light-color-text-default;
    }
  }

  .filterContainer {
    padding: $space-m;

    .divOfferings {
      margin-bottom: $space-m;
    }
  }
  .messageBoxDiv {
    border: $border-width-1 solid $blue-31;
    background-color: $blue-14;
    color: $blue-32;
    padding: $space-xs $space-s $space-xs $space-s;
    display: flex;

    .infoIconDiv {
      height: $space-m;
      width: $space-m;
    }
    .messageDiv {
      display: flex;
      align-items: center;
      padding-left: $space-s;

      & .close {
        color: $dark-color-text-default;
        opacity: 1;
        margin: 0;
        padding: 0;
        width: $space-m-xs;
        height: $space-m-xs;
        font-size: $text-m;

        &:hover,
        &:focus {
          opacity: 1;
        }
        &:hover {
          background-color: $grey-5;
        }
        &:focus {
          background-color: $grey-6;
        }
      }
      svg {
        width: 14px;
        height: 14px;
        fill: $light-color-text-default;
      }
    }
  }
  .messageBoxDivHide {
    display: none;
  }

  .divFilterTabs {
    text-align: center;
    margin: $space-1 0 $space-1 0;

    .buttonFilter {
      font-size: $text-m;
      background-color: $grey-12;
      border-color: $grey-30;
      padding: $space-s $space-m;
      height: $height-m-m;
      cursor: pointer;
    }
    // hover state interferes with touch event on iOS which made highlight not working properly
    // .buttonFilter:hover {
    //     background-color: rgba(137, 150, 164, 0.16);
    // }
    .highlight {
      background-color: $highlight-1;
    }

    .buttonOffering {
      border-radius: $border-radius-4 0px 0px $border-radius-4;
    }
    .buttonVersion {
      border-radius: 0;
    }
    .buttonType {
      border-radius: 0 $border-radius-4 $border-radius-4 0;
    }
  }
  .divFilterApplyReset {
    text-align: center;
  }
  .divHide {
    display: none;
  }
  .messageShown {
    max-height: calc(100vh - 300px) !important;
  }
  .messageNotShown {
    max-height: calc(100vh - 236px) !important;
  }

  overflow-x: hidden;
  .scrollToTop {
    position: fixed;

    right: $space-xl;
    background-color: $black;
    width: 50px;
    height: 50px;
    border-radius: 35px;
    opacity: 0.7;
    visibility: hidden;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;

    &.visible {
      opacity: 0.7;
      visibility: visible;
    }

    &.hidden {
      opacity: 0;
      visibility: hidden;
    }

    & > svg {
      bottom: 0;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.9);

      & > svg {
        bottom: 5px;
      }
    }

    & > svg {
      position: relative;
      fill: $white;
      width: 32px;
      height: 32px;
    }
  }
  .paddingBottom {
    bottom: 98px;
  }
}
.footer {
  position: fixed;
  left: 0;
  bottom: $space-m;
  width: 100%;
}
.invisible {
  display: none;
}
