@import "styles/modules/_colors";
@import "styles/modules/_mixins";
@import "styles/modules/_utility";
@import "styles/modules/_variables";

.horizontalDivider {
  border: $border-width-1 solid $grey-31;
  margin-top: $space-s;
  margin-bottom: $space-s;
}
