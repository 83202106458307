@import "styles/modules/_colors";
@import "styles/modules/_variables";

.releaseNote {
  padding-bottom: $space-s;
  padding-left: $space-xl;
  max-width: 50rem;

  .title {
    font-weight: bold;
    font-size: $text-m;
    color: $black;
  }
  .description {
    font-size: $text-m;
  }
  .documentLink {
    color: $black;
    font-size: $text-m;
    text-decoration: underline;
  }
}
.releaseNote:last-child {
  padding-bottom: 0;
}

.releaseNoteSmallDevice {
  padding-left: $space-xl;
  padding-bottom: $space-l;
  max-width: 50rem;

  .title {
    font-weight: bold;
    font-size: $text-l-xl;
    color: $black;
  }
  .description {
    font-size: $text-m;
  }
  .documentLink {
    color: $black;
    font-size: $text-m;
    text-decoration: underline;
  }
}
.releaseNoteSmallDevice:last-child {
  padding-bottom: $space-s;
}
