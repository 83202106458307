@import "styles/modules/_colors";
@import "styles/modules/_utility";

article {
  overflow-x: hidden;

  .desktop {
    bottom: 32px;
    right: 48px;
    width: 50px;
    height: 50px;
  }
  .smallDevice {
    bottom: 24px;
    right: 32px;
    width: 44px;
    height: 44px;
  }
}
.scrollToTop {
  position: fixed;
  background-color: $black;
  border-radius: 35px;
  opacity: 0.7;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;

  &.visible {
    opacity: 0.7;
    visibility: visible;
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  & > svg {
    bottom: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.9);

    & > svg {
      bottom: 5px;
    }
  }

  & > svg {
    position: relative;
    fill: $white;
    width: 32px;
    height: 32px;
  }
}
