@import "styles/modules/_colors";
@import "styles/modules/_mixins";
@import "styles/modules/_utility";
@import "styles/modules/_variables";

// Position the modal dialog at the bottom of the screen
.modalWindow {
  position: absolute !important;
  bottom: 0;
  left: 25%;
}
.modalBody {
  box-shadow:
    0px $border-radius-2 $border-radius-3 $black-3,
    0px $border-radius-4 $border-radius-5 $black-4;
  border-radius: $border-radius-5;
  background-color: $white;
  width: 200px;
  max-width: 328px;
  min-width: $space-m;
  padding: 0 !important;
}
.row {
  display: flex;
  align-items: center;
  height: 56px;
  font-size: $text-l-xl;
  font-weight: bold;
  padding: $space-s $space-l $space-s $space-l;

  &:hover {
    background-color: $grey-10;
  }
  &:focus {
    background-color: $grey-11;
  }
}
.modalHeader {
  padding: $space-m $space-s $space-m $space-l;
}
.modalTitle {
  padding-left: $space-s;
}
.close {
  color: $dark-color-text-default;
  opacity: 1;
  margin: 0;
  padding: 0;
  width: $height-m-m;
  height: $height-m-m;
  font-size: $text-m;
  border: none;
  border-radius: $border-radius-4;
  background-color: #fff;

  &:hover,
  &:focus {
    opacity: 1;
  }
}
