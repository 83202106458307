//Styles
@import "styles/modules/_variables";
@import "styles/modules/_colors";

// pseudo element
.field {
  display: inline-flex;
  position: relative;

  input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    -moz-appearance: none;
    appearance: none;
    background-color: white;
    margin: 0;
    /* these numbers are important and impact the perceived "circleness" */
    border: 1px solid $light-color-neutral;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    transform: translateY(-0.075rem);
    display: grid;
    place-content: center;
    margin: $space-5 $space-s 0 0;
  }

  input[type="radio"]::before {
    content: "";
    /* controls the size of the center */
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    appearance: none;

    /* "center dot" */
    box-shadow: inset 1rem 1rem white;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }

  input[type="radio"]:checked {
    background-color: $light-color-secondary;
    border: 1px solid $light-color-secondary;
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }

  label {
    font-family: AnovaUIRegular;
    padding-top: $space-xxxs;
    font-size: $text-s;
    color: $light-color-text-default;
    margin-bottom: 0;
    display: block;
    cursor: pointer;
  }

  .smallDeviceLabel {
    font-size: $text-m;
  }
}
