@import "styles/modules/_colors";
@import "styles/modules/_mixins";
@import "styles/modules/_utility";
@import "styles/modules/_variables";

.bottomPanel {
  display: flex;
  margin-top: $space-xl;
  min-height: $space-xl-s;

  .divMessageBox {
    flex: 20 1 auto;
    border: 1px solid $blue-31;
    background-color: $blue-14;
    color: $blue-32;
    padding-left: $space-s;
    margin-right: $space-inset-xl;
  }
  .divMessageBoxTable {
    padding-top: $space-xxs-m;
  }
  .messageBoxTable {
    width: 100%;
  }
  .message {
    font-family: AnovaUIRegular;
    font-size: $text-s;
  }
  .iconInfo {
    height: $space-m;
    width: $space-m;
    padding-right: $space-xs;
  }
  .tdClose {
    display: block;
  }
  .closeIcon {
    margin-right: $space-s-m;
    width: $space-s-l;
    height: $space-s-l;

    &:hover {
      background-color: $grey-5;
    }
    &:focus {
      background-color: $grey-6;
    }
  }
  .buttonInnerDiv {
    display: flex;
  }
  .button {
    font-family: AnovaUIRegular;
    font-size: $text-s;
    border: $border-width-1 solid transparent;
    border-radius: $border-radius-1;
    display: inline-flex;
    align-items: center;
    padding: $space-squish-s $space-s;
    height: auto;
  }
  .marginRight {
    margin-right: $space-s;
  }
  .marginLeft {
    margin-left: $space-s;
  }
  .buttonApplyFilters {
    background-color: $blue-32;
    color: $white;

    &:hover {
      background-color: $blue-12;
    }
    &:focus {
      background-color: $blue-13;
    }
  }
  .buttonApplyFiltersDisabled {
    color: $white;
    cursor: not-allowed;
    opacity: 0.4;
    background-color: $blue-32;
  }
  .buttonResetFilters {
    background-color: $grey-7;
    color: $black;

    &:hover {
      background-color: $grey-8;
    }
    &:focus {
      background-color: $grey-9;
    }
  }
  .buttonResetFiltersDisabled {
    cursor: not-allowed;
    opacity: 0.4;
    background-color: $grey-8;
    margin-left: $space-inline-s;
    color: $white;
  }
}
.divBottomPanelSmallDevice {
  text-align: center;

  .button {
    font-size: $text-m;
    border-radius: $border-radius-4;
    height: $height-l;
  }
  .marginRight {
    margin-right: $space-s;
  }
  .marginLeft {
    margin-left: $space-s;
  }
  .buttonApplyFilters {
    border: 1px solid $blue-32;
    background-color: $blue-32;
    color: $white;

    &:hover {
      color: $white-2;
    }
    &:focus {
      color: $white-1;
    }
  }
  .buttonApplyFiltersDisabled {
    border: 1px solid $blue-15;
    background-color: $blue-15;
    color: $white-3;
    cursor: not-allowed;
  }
  .buttonResetFilters {
    border: 1px solid $grey-30;
    background-color: $grey-12;
    color: $black;
    margin-left: $space-s;

    &:hover {
      background-color: $grey-10;
    }
    &:focus {
      background-color: $grey-11;
    }
  }
  .buttonResetFiltersDisabled {
    border: $border-width-1 solid $grey-12;
    background-color: $grey-12;
    color: $black-1;
    cursor: not-allowed;
  }
}
