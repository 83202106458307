@import "styles/modules/_colors";
@import "styles/modules/_mixins";
@import "styles/modules/_utility";
@import "styles/modules/_variables";

// Position the modal dialog at the bottom of the screen
.modalWindow {
  position: absolute !important;
  bottom: 0;
  left: 25%;
}
.modalBody {
  box-shadow:
    0px 2px 4px $black-3,
    0px 8px 16px $black-4;
  border-radius: $border-radius-5;
  background-color: $white;
  max-width: 328px;
  min-width: $space-m;
  padding: 0 !important;
  overflow: y;
}
.versionDiv {
  overflow: y;
}
.row {
  height: $height-xl;
  font-size: $text-l-xl;
  padding: $space-s $space-l $space-s $space-l !important;
  width: 100%;
  display: block;
  text-align: left;
  border: none;

  &:hover {
    background-color: $grey-10;
  }
  &:focus {
    background-color: $grey-11;
  }
}
