@import "styles/modules/_colors";
@import "styles/modules/_mixins";
@import "styles/modules/_utility";
@import "styles/modules/_variables";

.panel {
  padding: $space-m-s $space-l;

  .sectionTitle {
    font-weight: bold;
    color: $color-text-primary;
    font-size: $text-1;
  }
  .descriptiveText {
    font-size: $text-2;
    color: $color-label-text;
    padding-bottom: $space-block-m;
  }
  .releaseNoteTypeListDiv {
    flex-basis: 25%;
  }
  @media (min-width: 1007px) {
    .releaseNoteTypeListDiv {
      flex-basis: 48%;
    }
  }
}

.smallDevicePanel {
  .sectionTitle {
    font-weight: bold;
    color: $color-text-primary;
    font-size: $text-xl;
  }
  .descriptiveText {
    font-size: $text-2;
    color: $color-label-text;
    padding-bottom: $space-block-m;
  }
  .subTitle {
    font-size: $text-m;
    color: $color-label-text;
  }
}
