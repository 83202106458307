@import "styles/modules/_colors";
@import "styles/modules/_variables";

.resultContainer {
  padding: 0 $space-xxl $space-l $space-xxl;
  .cadenceHeader {
    font-weight: bold;
    font-size: 1.25rem;
    color: $blue-32;
    border-bottom: $border-width-1 solid $grey-34;
  }
}
.notFoundDiv {
  padding-top: $space-xl;
}
.notFound {
  display: flex;
  align-items: center;
  justify-content: center;
}
.notFoundLabel {
  padding-top: $space-s;
  padding-bottom: $space-xl;
}

.resultContainerSmallDevice {
  padding: 0 $space-l $space-l $space-l;
  .cadenceHeader {
    font-weight: bold;
    font-size: $text-xl;
    color: $blue-32;
    border-bottom: $border-width-1 solid $grey-34;
  }
}
