//Styles
@import "styles/modules/_colors";
@import "styles/modules/_utility";
@import "styles/modules/_variables";

.dropdown-menu {
  padding-top: $space-stack-xs;
  padding-bottom: $space-stack-xs;

  border-color: transparent;

  box-shadow: 0px 1px 16px 0px opacity($black, 0.6);
}

.dropdown-item {
  line-height: $text-m;
  font-size: $text-s;

  padding-top: $space-squish-s;
  padding-bottom: $space-squish-s;

  &:active,
  &.active {
    color: inherit;
    background-color: inherit;
  }
}
