@import "styles/modules/_colors";
@import "styles/modules/_mixins";
@import "styles/modules/_utility";
@import "styles/modules/_variables";

.selectedFilters {
  padding: $space-l $space-xxl 0 $space-xxl;

  .levelOneTokenLabel {
    font-size: $text-l;
    font-weight: bold;
    color: $black;
  }
  .levelOneToken {
    font-size: 0.875rem;
    border: transparent;
    border-radius: 12px;
    background-color: $blue-32;
    height: $height-m;
    margin-right: $space-inline-s;
    padding: $space-xxs $space-s;
    color: $white;
  }
  .levelTwo {
    margin-top: $space-s;
  }
  .levelTwoTokenLabel {
    font-size: $text-s;
    color: $grey-20;
  }
  .levelTwoToken {
    font-size: $text-s;
    border: transparent;
    border-radius: $border-radius-6;
    background-color: $blue-16;
    height: $height-xs;
    margin-right: $space-inline-s;
    padding: $space-xxs $space-s;
    color: rgba(0, 0, 0);
  }
}
.shareButton {
  float: right;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border-width: 1px;
  color: #1B1D22;
  background-color: #DDDFE4;
  font-size: 14px;
}
.shareImg {
  vertical-align: top;
  padding-top:3px;
  padding-right: 2px;
}
.mailtoHref {
  color: #1B1D22;
  text-decoration: none;
}
.popoverBody {
  padding: 16px 16px 0 16px;
  &:first-child {
    padding-bottom: 4px;
  }
  &:last-child {
    padding-top: 4px;
    padding-bottom: 16px;
  }
}
.shareItemUrlCopied {
  padding-left: 1px;
  &:first-child {
    padding-bottom: 4px;
  }
  &:last-child {
    padding-top: 4px;
  }
}
.shareItemSpan {
  &:first-child {
    padding-bottom: 4px;
  }
  &:last-child {
    padding-top: 4px;
  }
} 
.shareItemButton {
  padding: 4px 16px 4px 0;
  width: 100%;
  text-align: left;
  border-width: 1px;
  border-color: rgba(255,255,255,0.0);
  background-color:rgba(255,255,255,0.0);

  &:hover {
    background-color: #F4F4F6;
    color:#1B1D22;
  }
  &:focus {
    background-color: #CEE4FD;
    color:#054A99;
  }
}
.shareItemButtonCopied {
  padding: 4px 16px 4px 0;
  width: 100%;
  text-align: left;
  background-color: #F8FCF8;
  border-color: #42B348;
  color: #328636;
}
.shareCopiedImgContainer {
  width: 16px;
  display: inline-block;
}
.shareItemButtonCopyImg {
  padding: 3px 2px 2px 2px;
  vertical-align: top;
  filter: invert(44%) sepia(31%) saturate(1020%) hue-rotate(73deg) brightness(89%) contrast(84%);
}
.selectedFiltersSmallDevice {
  padding: $space-m 16px 0 16px;

  .levelOneTokenLabel {
    font-size: 1.2rem;
    font-weight: bold;
    color: rgba(0, 0, 0);
  }
  .levelOneToken {
    font-size: 1rem;
    border: transparent;
    border-radius: 14px;
    background-color: rgba(3, 103, 176);
    height: 28px;
    margin-right: $space-inline-s;
    padding: $space-squish-s $space-s;
    color: rgba(255, 255, 255);
  }
  .levelTwo {
    margin-top: 0.5rem;
  }
  .levelTwoTokenLabel {
    font-size: 1rem;
    color: rgba(107, 119, 140);
  }
  .levelTwoToken {
    font-size: 1rem;
    border: transparent;
    border-radius: 14px;
    background-color: rgba(3, 103, 176, 0.2);
    height: 28px;
    margin-right: $space-inline-s;
    padding: $space-squish-s $space-s;
    color: rgba(0, 0, 0);
  }
}
 
