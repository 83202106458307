@import "styles/modules/_colors";
@import "styles/modules/_mixins";
@import "styles/modules/_utility";
@import "styles/modules/_variables";

.categoryList {
  padding-top: $space-block-xs;
}
.categoryItem {
  list-style-type: none;
}
.padding {
  padding: $space-block-xxs 0 $space-block-xxs 0;
}
.smallDevicePadding {
  padding: $space-block-s 0 $space-block-s 0;
}
