@import "styles/modules/_colors";
@import "styles/modules/_mixins";
@import "styles/modules/_utility";
@import "styles/modules/_variables";

.offeringItem {
  list-style-type: none;
  @include scrollbar;
}
.padding {
  padding: $space-block-xxs 0 $space-block-xxs 0;
}
.smallDevicePadding {
  padding: $space-block-s 0 $space-block-s 0;
}

