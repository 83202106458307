//Styles
@import "styles/modules/_variables";
@import "styles/modules/_colors";

.field {
  display: inline-flex;
  position: relative;
  overflow-x: hidden;

  .checkbox {
    flex-shrink: 0;
    margin-top: $space-5;
    //margin-right: $space-inline-m;
    border: 1px solid $light-color-neutral;
    color: $light-color-ui-content;
    width: $space-s-l;
    height: $space-s-l;
    background-color: $light-color-ui-content;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    cursor: pointer;

    &:checked {
      border: 1px solid $light-color-secondary;
      background-color: $light-color-secondary;
    }

    &:checked + label::before {
      content: "\2713";
      display: block;
      color: $light-color-ui-content;
      position: absolute;
      left: 0.1rem;
      width: $space-s-l;
      height: $space-s-l;
    }
  }
  .conditionalCheckbox {
    flex-shrink: 0;
    margin-top: $space-5;
    border: 1px solid $light-color-neutral;
    color: $light-color-ui-content;
    width: $space-s-l;
    height: $space-s-l;
    background-color: $light-color-ui-content;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    cursor: pointer;
    background-image: url("conditionalHighlightingSquare.svg");
    background-size: 10px 10px;
    background-position: 1.2px 1.2px;
  }

  label {
    font-family: AnovaUIRegular;
    padding-top: $space-xxxs;
    font-size: $text-s;
    color: $light-color-text-default;
    margin-bottom: 0;
    display: block;
    cursor: pointer;
  }

  .smallDeviceInput {
    //width: $space-m;
    //height: $space-m;
    margin-top: $space-6;
  }
  .smallDeviceLabel {
    font-size: $text-m;
  }
}
.ellipse {
  &.field {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
  }
  label {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
