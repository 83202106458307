//Styles
@import "styles/modules/_utility";

// --- Colors ---

$black: hsl(0, 0%, 0%);
$white: hsl(0, 100%, 100%);
$red: #ff0000;

$red-violet: hsl(329, 75%, 43%);
$red-violet-2: hsl(329, 75%, 30%);
$red-violet-3: hsl(328.7, 38.7%, 46.7%);

$bronze: hsl(40, 100%, 28%);

$blue-1: hsl(205, 98%, 18%);
$blue-2: hsl(205, 97%, 27%);
$blue-3: hsl(205, 25%, 70%);
$blue-4: hsl(209, 82%, 34%);
$blue-5: hsl(205, 97%, 63%);
$blue-6: hsl(205, 97%, 41%);
$blue-7: hsl(202, 100%, 35%);
$blue-8: hsl(201, 62%, 41%);
$blue-9: hsl(201, 62%, 86%);
$blue-10: hsl(205, 98%, 18%);
$blue-11: hsl(211, 13%, 59%);
$blue-12: rgba(3, 103, 176, 0.8);
$blue-13: rgba(3, 103, 176, 0.6);
$blue-14: rgba(4, 120, 206, 0.03);
$blue-15: rgba(4, 120, 206, 0.24);
$blue-16: rgba(3, 103, 176, 0.2);
$blue-17: rgba(4, 120, 206, 0.8);
$blue-18: rgba(4, 120, 206, 0.6);

$blue-31: rgb(4, 120, 206);
$blue-32: rgb(3, 103, 176);
$blue-33: rgb(1, 52, 89);
$blue-34: rgb(2, 79, 135);
$blue-35: rgb(5, 74, 153);

$gray-1: hsl(210, 17%, 98%);
$gray-2: hsl(218, 12%, 83%);
$gray-3: hsl(216, 13%, 53%);
$grey-4: hsl(180, 9%, 98%);
$grey-5: rgba(137, 150, 164, 0.1);
$grey-6: rgba(137, 150, 164, 0.25);
$grey-7: rgba(137, 150, 164, 0.2);
$grey-8: rgba(137, 150, 164, 0.3);
$grey-9: rgba(137, 150, 164, 0.35);
$grey-10: rgba(137, 150, 164, 0.16);
$grey-11: rgba(137, 150, 164, 0.24);
$grey-12: rgba(137, 150, 164, 0.08);
$grey-13: rgba(120, 132, 151, 0.08);
$grey-14: rgba(120, 132, 151, 0.16);
$grey-15: rgba(120, 132, 151, 0.24);
$grey-16: rgb(120, 132, 151);
$grey-20: rgb(107, 119, 140);
$grey-30: rgb(137, 150, 164);
$grey-31: rgba(205, 209, 216, 0.5);
$grey-32: rgb(202, 206, 206);
$grey-33: rgb(153, 161, 167);
$grey-34: rgb(205, 209, 216);
$grey-35: rgba(255, 255, 255, 0);

$white-1: rgba(255, 255, 255, 0.6);
$white-2: rgba(255, 255, 255, 0.8);
$white-3: rgba(255, 255, 255, 0.64);
$white-4: rgb(249, 250, 250);
$white-5: rgb(244, 244, 246);
$white-6: rgb(206, 228, 253);
$white-7: rgb(248, 252, 248);


$black: rgb(0, 0, 0);
$black-1: rgba(0, 0, 0, 0.32);
$black-2: rgba(0, 0, 0, 0.2);
$black-3: rgba(0, 0, 0, 0.04);
$black-4: rgba(0, 0, 0, 0.08);
$black-5: rgba(0, 0, 0, 30%);
$black-6: rgb(27, 29, 34);

$green-1: rgb(66, 179, 72);
$green-2: rgb(50, 134, 54);


$dark-color-sas-banner: $blue-1;
$dark-color-sas-primary: $blue-1;
$dark-color-sas-secondary: $blue-2;
$dark-color-text-default: $white;
$dark-color-neutral: $blue-3;
$dark-color-secondary: $red-violet;
$dark-color-ui-border: $blue-5;
$dark-color-filter-banner: $blue-10;

$light-color-ui-content: $white;
$light-color-ui-medium: $gray-1;
$light-color-text-default: $black;
$light-color-neutral: $gray-3;
$light-color-ui-border: $gray-2;
$light-color-text-link: $blue-4;
$light-color-primary: $blue-6;
$light-color-secondary: $red-violet-3;
$light-color-warning: $bronze;

$light-color-outer-container: $grey-4;

$solid-color-neutral: $blue-11;

$color-text-primary: #0367b0;
$color-label-text: #667185;
$color-container-border-4: #dde0e4;

$highlight-1: rgba(134, 19, 78, 0.24);
