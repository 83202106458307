.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
