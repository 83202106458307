@import "styles/modules/_colors";
@import "styles/modules/_mixins";
@import "styles/modules/_utility";
@import "styles/modules/_variables";

.messageBoxDiv {
  border: 1px solid $blue-31;
  background-color: $blue-14;
  color: $blue-32;
  padding: $space-xs $space-s $space-xs $space-s;
  display: flex;

  .infoIconDiv {
    height: $space-m;
    width: $space-m;
  }
  .messageDiv {
    display: flex;
    align-items: center;
    padding-left: $space-s;

    & .close {
      color: $dark-color-text-default;
      opacity: 1;
      margin: 0;
      padding: 0;
      width: $space-m-xs;
      height: $space-m-xs;
      font-size: $text-m;

      &:hover,
      &:focus {
        opacity: 1;
      }
      &:hover {
        background-color: $grey-5;
      }
      &:focus {
        background-color: $grey-6;
      }
    }
    svg {
      width: 14px;
      height: 14px;
      fill: $light-color-text-default;
    }
  }
}
.messageBoxDivHide {
  display: none;
}
