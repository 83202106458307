@import "styles/modules/_colors";
@import "styles/modules/_mixins";
@import "styles/modules/_utility";
@import "styles/modules/_variables";

// ***************************test flex code
.container {
  display: flex;
  gap: 1rem;
}
.one {
  flex: 2;
  background: green;
}
.two {
  background: #bb6fad;
  flex: 1;
}
.three {
  background: #6560ab;
  flex: 1;
}
@media only screen and (min-width: 1008px) and (max-width: 1272px) {
  .one {
    flex: 2;
    background: blue;
  }
  .two {
    flex: 1;
    background: lightblue;
  }
  .three {
    flex: 1;
    background: purple;
  }
}
@media only screen and (max-width: 1007px) {
  .container {
    flex-wrap: wrap;
  }
  .one {
    flex: 1 1 100%;
    background: yellow;
  }
  .two {
    flex-grow: 1;
    background: lightcoral;
  }
  .three {
    flex-grow: 1;
    background: lightseagreen;
  }
}
// ***************************End test flex code

.filterContainer {
  margin-top: $space-m;
  margin-bottom: $space-m;
  background-color: $white;
  border: $grey-31;
  border-radius: $border-radius-2;
  box-shadow: 0 $border-radius-2 $border-radius-7 rgb(0 0 0 / 30%);
  overflow-y: auto;
}
.filterLeftPadding {
  width: $space-xxl;
  display: inline-block;
}
.toggleIcon {
  width: $space-m;
  height: $space-m;
  margin-left: $space-m;
}

.filterTitle {
  display: inline-block;
}
.topBottomMargin {
  margin-top: $space-m;
  margin-bottom: $space-m;
}
.filterBody {
  margin-left: $space-xxl;
  margin-right: $space-xxl;
}
.filterApplyResetContainer {
  margin: $space-m $space-l $space-m $space-l;
}

.filterToggle {
  background-color: $white;
  border: $grey-31;
  border-radius: $border-radius-2;
  box-shadow: 0 $border-radius-2 $border-radius-7 rgb(0 0 0 / 30%);
  overflow-y: auto;
}
.filterToggleMargin {
  margin-top: $space-m;
  margin-bottom: $space-m-xs;
}
.toggleHeader {
  text-decoration: none;
  background-color: #fff;
  border: none;
}
.filterBanner {
  font-size: $text-m;
  padding-top: $space-m;
  padding-bottom: $space-m;
  font-weight: bold;
  align-items: center;
  color: $dark-color-filter-banner;
}
.filterBannerPadding {
  padding-left: $space-m;
  display: flex;
}

.toggleTitle {
  font-size: $text-m;
  font-weight: bold;
  color: #013459;
}
.toggleCollapseCard {
  padding-top: $space-xxs !important;
}

.filterActions {
  float: right;
}
.filterCard {
  background-color: #ffffff;
  border: 1px solid $color-container-border-4;
  border-radius: $border-radius-2;
  box-shadow: $shadow-depth-s;
}
.flexContainer {
  display: flex;
  gap: $space-block-m;
}
.offeringCard {
  flex: 2;
}
.cadenceCard {
  flex: 1;
}
.typeCard {
  flex: 1;
}
@media only screen and (min-width: 1008px) and (max-width: 1272px) {
  .offeringCard {
    flex: 2;
  }
  .cadenceCard {
    flex: 1;
  }
  .typeCard {
    flex: 1;
  }
}
@media screen and (max-width: 1007px) {
  .flexContainer {
    flex-wrap: wrap;
  }
  .offeringCard {
    flex: 1 1 100%;
  }
  .cadenceCard {
    flex-grow: 1;
  }
  .typeCard {
    flex-grow: 1;
  }
}

.smallDeviceFilterDiv {
  margin-top: $space-m;
  margin-bottom: $space-l;
  text-align: center;
}
.smallDeviceFilterButton {
  display: inline-flex;
  align-items: center;
  border: 1px $solid-color-neutral;
  border-radius: $border-radius-4;
  background-color: $grey-12;
  font-size: $text-m;
  padding: $space-m $space-xl;
}
.smallDeviceFilterButton:hover {
  background-color: $grey-10;
}
.smallDeviceFilterButton:focus {
  background-color: $grey-11;
}
