@import "styles/modules/_colors";
@import "styles/modules/_mixins";
@import "styles/modules/_utility";
@import "styles/modules/_variables";

.toggle {
  padding: $space-s $space-xxl $space-m $space-xxl;
  .linkButton {
    color: $light-color-text-link;
    padding-right: $space-squish-s;
    padding-left: $space-squish-s;
    height: 1.5rem;
  }
  .linkButton:hover {
    background-color: $grey-5;
  }
  .linkButton:focus {
    background-color: $grey-6;
  }
  .linkButton:disabled {
    background-color: transparent;
    color: $black-1;
  }
  .linkButtonLabel {
    font-family: AnovaUIRegular;
    font-size: $text-xs;
    color: $black;
    padding-left: $space-inline-xs;
  }
  .linkButtonLabelDisabled {
    font-size: $text-xs;
    color: $black-1;
    padding-left: $space-inline-xs;
  }
  .linkButtonIconDisabled {
    opacity: 0.32;
  }
  .verticalDivider {
    border: 1px solid #cdd1d8;
    margin-left: $space-inline-s;
    margin-right: $space-inline-s;
    height: $height-m;
  }
}

.toggleSmallDevice {
  text-align: center;

  .linkButton {
    color: $light-color-text-link;
    padding: $space-block-s $space-block-m 0 $space-block-m;
    background-color: transparent;
    border: transparent 1px solid;
    border-radius: $border-radius-4;
  }
  .linkButton:hover {
    background-color: $grey-10;
  }
  .linkButton:focus {
    background-color: $grey-11;
  }
  .linkButton:disabled {
    background-color: transparent;
    color: $black-1;
  }
  .linkButtonLabel {
    font-size: 1rem;
    color: $black;
    padding-left: $space-inline-xs;
  }
  .linkButtonLabelDisabled {
    font-size: 1rem;
    color: $black-1;
    padding-left: $space-inline-xs;
  }
  .linkButtonIconDisabled {
    opacity: 0.32;
  }
  .verticalDivider {
    border: 1px solid #cdd1d8;
    margin-left: $space-inline-s;
    margin-right: $space-inline-s;
    height: $height-m;
  }
}
