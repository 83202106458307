@import "styles/modules/_colors";
@import "styles/modules/_mixins";
@import "styles/modules/_utility";
@import "styles/modules/_variables";

.cadenceList {
  .item {
    list-style-type: none;
    padding: $space-block-xs 0 $space-block-xs 0;
  }

  .smallDeviceItem {
    height: $height-m-m;
    display: flex;
    align-items: center;
  }
}
