//Styles
@import "styles/modules/_variables";
@import "styles/modules/_colors";

.category {
  padding-left: 80px;
  padding-bottom: $space-xl;

  .header {
    padding-left: $space-inline-s;
    font-weight: bold;
    font-size: $text-l;
  }
}

.categorySmallDevice {
  padding-top: $space-s;
  padding-bottom: $space-s;
  margin-top: $space-s;
  margin-bottom: $space-s;

  .header {
    padding-left: $space-inline-s;
    font-weight: bold;
    font-size: $text-l-xl;
  }
  .innerDiv {
    padding-top: $space-xs;
    padding-bottom: $space-xs;
  }
}
.categorySmallDevice:last-child {
  margin-bottom: $space-xl;
}
