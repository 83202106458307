@import "styles/modules/_colors";
@import "styles/modules/_mixins";
@import "styles/modules/_utility";
@import "styles/modules/_variables";

.item {
  list-style-type: none;

  .innerFromDiv,
  .innerToDiv {
    padding-top: $space-s;
  }
  .field {
    padding-left: $space-s;
  }
  .range {
    padding-left: $space-m-l;
  }
  .fromSpan,
  .toSpan {
    font-size: $text-s;
    color: $grey-20;
    min-width: $space-l-s;
    margin-bottom: $space-xs;
  }
  .toSpanPaddingRight {
    padding-right: $space-m-s;
  }
  select {
    padding: $space-squish-xs $space-squish-xs $space-squish-xs $space-xxs;
    -webkit-border-radius: $border-radius-3;
    -moz-border-radius: $border-radius-3;
    border-radius: $border-radius-3;
    border: transparent;
  }
  .rangeSelect {
    font-family: AnovaUIRegular;
    font-size: $text-s;
    margin-left: $space-inline-s;
    height: $height-m-s;
    background-color: $grey-10;
    margin-bottom: $space-xs;

    &:hover {
      margin-left: $space-inline-s;
      height: $height-m-s;
      background-color: $grey-8;
    }
    &:focus {
      margin-left: $space-inline-s;
      height: $height-m-s;
      background-color: $grey-9;
    }
  }
  .rangeOption {
    font-size: $base-font-size;
    background-color: $white;
    // Not able to style selection option. Idealy this is what we want:
    padding: $space-block-xs $space-s $space-block-xs $space-m;
    &:hover,
    &:focus {
      background-color: $grey-8;
      box-shadow: 0px $space-xxs $space-s 0px $black-2;
    }
  }
}

.smallDeviceItem {
  list-style-type: none;
  margin-left: $space-m;

  .rangeRadio {
    margin-top: $space-s;
  }
  .innerFromDiv,
  .innerToDiv {
    padding-top: $space-s;
  }
  .field {
    padding-left: $space-s;
  }
  .range {
    padding-left: $space-m-l;
  }
  .fromSpan,
  .toSpan {
    padding-left: $space-inline-s;
    font-size: $text-s;
    color: $grey-20;
    margin-bottom: $space-xs;
  }
  .fromSpanPaddingRight {
    padding: $space-s;
  }
  .rangeSelect {
    font-size: $text-m;
    height: $height-m-l;
    text-align: left !important;
    padding-left: $space-m;
    border-radius: $border-radius-4;
    border: 1px solid $grey-30;
    background-color: $grey-12;
    color: $black;
    margin-bottom: $space-xs;

    &:disabled {
      border: 1px solid $grey-12;
      background-color: $grey-12;
      color: $black-1;
      cursor: not-allowed;
    }
    &:hover {
      background-color: $grey-10;
    }
    &:focus {
      background-color: $grey-11;
    }
  }
  .regularWidth {
    width: 238px;
  }
  .longWidth {
    width: 280px;
  }
}
