@import "styles/modules/_colors";
@import "styles/modules/_variables";

.cadence {
  .header {
    font-weight: regular;
    font-size: $text-m;
    color: $blue-33;
    border-bottom: $border-width-1 solid $grey-34;
  }
  .desktop {
    margin-bottom: $space-block-xs + $space-block-m;
  }
  .smallDevice {
    margin-bottom: 0.25rem;
  }
}
