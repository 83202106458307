.navbar {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 2rem;
  padding-right: 2.75rem;

  .navbar-nav {
    .nav-link {
      color: $dark-color-text-default;
      padding-top: 0;
      padding-bottom: 0;

      &:visited,
      &:focus,
      &:hover {
        color: $dark-color-text-default;
      }
    }
  }
}

.nav-item {
  margin-left: $space-inline-s;
  margin-right: $space-inline-s;

  white-space: nowrap;
}

.nav-link {
  white-space: nowrap;
}
