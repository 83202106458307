//Styles
@import "styles/modules/_colors";
@import "styles/modules/_variables";
@import "styles/fonts/fonts";

html {
  //font-size: $base-font-size;
  font-size: 16px;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;

  //font-size: $text-s;
  font-family: $base-font-family;

  background-color: $white;
  //color: rgb(51, 51, 51);

  &.theme-inverted {
    &:before {
      content: "";
      z-index: 9999;
      outline: 2999px solid invert;
    }
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }

  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

.LPMcontainer:global {
  transform: (rotate(180deg));
}
.card {
  border: none;
}
