@import "styles/modules/_colors";
@import "styles/modules/_variables";

.product {
  padding-top: $space-m;
  padding-left: $space-l;

  .header {
    padding-left: $space-inline-xs;
    font-weight: regular;
    font-size: $text-l;
  }
  .innerDiv {
    display: flex;
  }
}
.releaseNoteTypeInProduct {
  padding-top: 0;
}

.productSmallDevice {
  padding-top: $space-s;
  padding-bottom: $space-s;
  padding-left: $space-l;

  .header {
    padding-left: $space-inline-xs;
    font-weight: regular;
    font-size: $text-l;
  }
  .innerDiv {
    padding-top: $space-s;
    padding-bottom: $space-s;
    display: flex;
  }
}
.productSmallDevice:first-child {
  padding-top: 0;
}
