//Styles
@import "./_variables";

@mixin scrollbar {
  /* width */
  &::-webkit-scrollbar {
    width: 4px;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $light-color-neutral;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    background-clip: padding-box;
  }
}

@mixin section-x-padding {
  padding-left: 2rem;
  padding-right: 2.75rem;
}

@mixin space-squish($space) {
  padding: $space * 0.5;
}

@mixin space-squish-xs {
  @include space-squish($space-xs);
}

@mixin space-squish-s {
  @include space-squish($space-s);
}
