@import "styles/modules/_colors";
@import "styles/modules/_mixins";
@import "styles/modules/_utility";
@import "styles/modules/_variables";

.panel {
  padding: $space-m-s $space-l;

  .descriptiveTextDiv {
    padding-top: $space-xxs;
  }
  .descriptiveText {
    font-size: $text-2;
    color: $color-label-text;
    padding-bottom: $space-block-m;
  }
  .imageCheck {
    padding-left: $space-squish-s;
  }
  .selectAllClearAll {
    float: right;
    padding-right: $space-block-m;
  }
  .linkButton {
    color: $light-color-text-link;
    padding-right: $space-squish-s;
    padding-left: $space-squish-s;
    height: $height-m;

    &:hover {
      background-color: $grey-5;
    }
    &:focus {
      background-color: $grey-6;
    }
  }
  .linkButtonLabel {
    font-family: AnovaUIRegular;
    font-size: $text-xs;
    color: $black;
    padding-left: $space-inline-xs;
  }
  .scrollDiv {
    overflow-y: auto;
    height: 300px;
    @include scrollbar;
  }
  .sectionTitle {
    font-weight: bold;
    color: $color-text-primary;
    font-size: $text-1;
  }
  .subTitle {
    font-family: AnovaUIRegular;
    font-size: $text-s;
    color: $color-label-text;
    padding-bottom: $space-block-s;
  }
  .paddingTop {
    padding-top: $space-block-s;
  }
  .offeringListDiv {
    max-height: 290px;
  }
  .offeringList {
    list-style-type: none;
    padding-left: $space-m;
  }
  .verticalDivider {
    border: $border-width-1 solid #cdd1d8;
    margin-left: $space-inline-s;
    margin-right: $space-inline-s;
    height: $space-l;
  }
}

.smallDevice {
  .descriptiveTextDiv {
    padding-top: $space-xxs;
  }
  .descriptiveText {
    font-size: $text-s;
    color: $color-label-text;
    padding-bottom: $space-block-m;
  }
  .sectionTitle {
    font-weight: bold;
    color: $color-text-primary;
    font-size: $text-xl;
  }
  .scrollDiv {
    overflow-y: auto;
    height: calc(100vh - 400px);
    @include scrollbar;
  }
  .scrollDivMessageNotShown {
    overflow-y: auto;
    height: calc(100vh - 370px);
    @include scrollbar;
  }
  .subTitle {
    font-size: $text-m;
    color: $color-label-text;
    &:nth-child(1) {
      padding-top: $space-s;
    }
  }
  .paddingTop {
    padding-top: $space-base-l;
  }
  .offeringListDiv {
    margin-right: $space-m;
    padding-top: $space-block-s;
  }
  .selectAllClearAll {
    text-align: center;

    .linkButton {
      color: $light-color-text-link;
      padding: $space-s;
      background-color: transparent;
      border: transparent $border-width-1 solid;
      border-radius: $border-radius-4;

      &:hover {
        background-color: $grey-10;
      }
      &:focus {
        background-color: $grey-11;
      }
      &:disabled {
        background-color: transparent;
      }
    }
    .linkButtonLabel {
      font-size: $text-m;
      color: $black;
      padding-left: $space-inline-xs;
    }
    .linkButtonLabelDisabled {
      font-size: $text-m;
      color: $black-1;
      padding-left: $space-inline-xs;
    }
    .linkButtonIconDisabled {
      opacity: 0.32;
    }
  }
}
