button {
  border: 1px solid $light-color-neutral;
  color: $light-color-text-default;
  background-color: opacity($light-color-neutral, 0.1);
  font-size: $text-s;
  font-family: $base-font-family;
  cursor: pointer;

  @include space-squish-s;

  &:hover {
    background-color: opacity($light-color-neutral, 0.2);
  }

  &:focus {
    background-color: opacity($light-color-neutral, 0.25);
  }
}
