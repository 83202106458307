@import "styles/modules/_colors";
@import "styles/modules/_mixins";
@import "styles/modules/_utility";
@import "styles/modules/_variables";

.typeListDiv {
  .item {
    padding: $space-block-xxs 0 $space-block-xxs 0;
  }
  .smallDeviceItem {
    height: $height-m-m;
    list-style-type: none;
    display: flex;
    align-items: center;
    padding: $space-block-xxs 0 $space-block-xxs 0;
  }
  .indent {
    padding-left: $space-2;
    padding-right: $space-l;
  }
}
