@import "styles/modules/_colors";
@import "styles/modules/_mixins";
@import "styles/modules/_utility";
@import "styles/modules/_variables";

.panel {
  padding: $space-m-s $space-l;

  .sectionTitle {
    font-weight: bold;
    color: $color-text-primary;
    font-size: $text-1;
  }
  .descriptiveText {
    font-size: $text-2;
    color: $color-label-text;
    padding-bottom: $space-block-m;
  }
  .subTitle {
    font-family: AnovaUIRegular;
    font-size: $text-s;
    color: $color-label-text;
    padding-bottom: $space-block-s;
  }
  .padding {
    padding-bottom: $space-block-s;
  }
  .paddingTop {
    padding-top: $space-s;
  }
  .scrollDiv {
    overflow-y: auto;
    height: 300px;
    @include scrollbar;
  }
  @media (min-width: 1007px) {
    .scrollDiv {
      flex-basis: 48%;
    }
  }
}

.smallDevicePanel {
  .sectionTitle {
    font-weight: bold;
    color: $color-text-primary;
    font-size: $text-xl;
  }
  .descriptiveText {
    font-size: $text-s;
    color: $color-label-text;
    padding-bottom: $space-block-m;
  }
  .subTitle {
    font-size: $text-m;
    color: $color-label-text;
  }
  .padding {
    padding: $space-block-s 0 $space-block-s 0;
  }
  .paddingTop {
    padding-top: $space-l;
  }
  .labelCadence {
    font-size: $text-m;
    color: $grey-20;
  }
  .labelCadenceDesc {
    font-size: $text-m;
    color: $black;
  }
  .space {
    padding: $space-m;
  }
  .cadenceListDiv:last-child {
    padding-bottom: $space-m;
  }
  .scrollDiv {
    overflow-y: auto;
    height: calc(100vh - 350px);
    @include scrollbar;
  }
  .scrollDivMessageNotShown {
    overflow-y: auto;
    height: calc(100vh - 300px);
    @include scrollbar;
  }
}
