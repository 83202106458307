@import "styles/modules/_colors";
@import "styles/modules/_variables";

.offering {
  padding-left: $space-4;
  padding-bottom: $space-xl;

  .header {
    padding-left: 0.5rem;
    font-weight: bold;
    font-size: $text-l;
  }
  .innerDiv {
    display: flex;
  }
}

.offeringSmallDevice {
  padding-top: $space-s;
  padding-bottom: $space-s;
  margin-top: $space-s;
  margin-bottom: $space-s;

  .header {
    padding-left: $space-inline-s;
    font-weight: bold;
    font-size: $text-l-xl;
  }
  .innerDiv {
    padding-top: $space-s;
    padding-bottom: $space-s;
    display: flex;
  }
}
.offeringSmallDevice:last-child {
  margin-bottom: $space-l;
}
