@import "styles/modules/_colors";
@import "styles/modules/_mixins";
@import "styles/modules/_utility";
@import "styles/modules/_variables";

.filters {
  background-color: $light-color-outer-container;
  height: auto;
  .title {
    font-size: $text-xxl;
    padding-top: $space-l;
    color: $blue-33;
  }
  .subtitle {
    font-size: $text-m;
    color: $blue-33;
    margin-top: $space-xxs;
  }
  .smallDeviceTitle {
    font-size: $text-l-xl;
    padding-top: $space-l;
    color: $blue-33;
  }
  .smallDeviceSubtitle {
    color: $blue-33;
    margin-top: $space-xxs;
  }
}
.filters.padding {
  padding: 0 $space-xxl;
  padding-bottom: $space-m-xs;
}
.filters.smallDevicePadding {
  padding: 0 $space-m;
  padding-bottom: $space-m;
}
