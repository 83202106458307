$base-font-size: 0.875rem;
$base-font-family:
  AnovaRegular,
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  Roboto,
  Helvetica,
  Arial,
  sans-serif;

$space-xxxs: 0.0625rem;
$space-xxs: 0.125rem;
$space-xxs-m: 0.188rem;
$space-xs: 0.25rem;
$space-s: 0.5rem;
$space-s-m: 0.625rem;
$space-s-l: 0.875rem;
$space-m: 1rem;
$space-m-s: 1.125rem;
$space-m-xs: 1.25rem;
$space-m-l: 1.3rem;
$space-l: 1.5rem;
$space-l-s: 1.875rem;
$space-xl: 2rem;
$space-xl-s: 2.25rem;
$space-xxl: 3rem;
$space-xxxl: 3.25rem;
$space-1: 0.938rem;
$space-2: 2.2rem;
$space-3: 2.625rem;
$space-4: 5rem;
$space-5: 0.2rem;
$space-6: 0.3rem;
$space-7: 0.375rem;

$space-inline-xxs: $space-xxs;
$space-inline-xs: $space-xs;
$space-inline-s: $space-s;
$space-inline-m: 0.5rem;

$space-stack-xxs: 0.125rem;
$space-stack-xs: $space-xs;
$space-stack-s: $space-s;
$space-stack-m: $space-m;
$space-stack-xl: 2rem;

$space-squish-m: $space-m * 0.5;
$space-squish-xs: $space-xs * 0.5;
$space-squish-s: $space-s * 0.5;

$space-inset-xl: 2rem;
$space-inset-m: 1rem;

$space-block-xxs: 0.125rem;
$space-block-xs: 0.25rem;
$space-block-s: 0.5rem;
$space-block-m: 1rem;
$space-block-l: 1.125rem;
$space-base-l: 1.5rem;

$text-xxxs: 0.5rem;
$text-xxs: 0.6875rem;
$text-xs: 0.75rem;
$text-s: 0.875rem;
$text-m: 1rem;
$text-l: 1.125rem;
$text-xl: 1.25rem;
$text-l-xl: 1.2rem;
$text-xxl: 1.57rem;
$text-1: 0.9875rem;
$text-2: 0.775rem;

$border-width-1: 1px;

$border-radius-1: 1px;
$border-radius-2: 2px;
$border-radius-3: 4px;
$border-radius-4: 8px;
$border-radius-5: 16px;
$border-radius-6: 12px;
$border-radius-7: 6px;

$height-ss: 1rem;
$height-s: 1.3rem;
$height-xs: 1.375rem;
$height-m: 1.5rem;
$height-m-ss: 1.56rem;
$height-m-s: 1.875rem;
$height-m-m: 2rem;
$height-m-xs: 2.355rem;
$height-m-m: 2.375rem;
$height-m-l: 2.75rem;
$height-l: 2.625rem;
$height-xl: 3.5rem;
$height-1: 1.25rem;
$height-2: 1.37rem;
$height-3: 1.0625rem;
$height-4: 1.4rem;
$height-5: 3.625rem;
$height-6: 5.125rem;
$height-7: 1.7rem;

$shadow-depth-s:
  0px 4px 8px 0px rgba(0, 0, 0, 0.08),
  0px 2px 2px 0px rgba(0, 0, 0, 0.04);
