@import "styles/modules/_colors";
@import "styles/modules/_variables";

.releaseNoteType {
  padding-top: $space-s;
  padding-left: 76px;

  .header {
    padding-left: $space-inline-xs;
    color: $blue-32;
    font-size: $text-l;
    padding-bottom: $space-s;
  }
  .innerDiv {
    display: flex;
  }
}

.releaseNoteTypeSmallDevice {
  padding-left: 2.9rem;

  .header {
    color: $blue-32;
    font-size: $text-l-xl;
    padding-top: $space-s;
    padding-bottom: $space-s;
    height: 41px;
  }
  .innerDiv {
    padding-top: $space-s;
    padding-bottom: $space-s;
    display: flex;
  }
}
.releaseNoteTypeSmallDevice:first-child {
  padding-top: 0;
}
