@import "styles/modules/_colors";
@import "styles/modules/_mixins";
@import "styles/modules/_utility";
@import "styles/modules/_variables";

.header.topHeader {
  font-family: $base-font-family;
  background-color: $dark-color-sas-banner;
  justify-content: space-between !important;
  padding: $space-m $space-xxl $space-m $space-3;
}
.logo {
  max-width: 26%;
}
.backToMySas {
  color: $white;
  background-color: $blue-31;
  border: $border-width-1 solid $blue-31;
  padding: $space-xs $space-s $space-xs $space-s;

  &:hover {
    background-color: $blue-17;
  }
  &:focus {
    background-color: $blue-18;
  }
}
.header.banner {
  background-color: $blue-34;
  .title {
    color: $white;
    font-size: $text-l-xl;
  }
}
.header.padding {
  padding: $space-7 $space-xxl;
}
.helpButtonToolbar {
  width: $space-m-xs;
  height: $space-m-xs;
  margin: $space-s;
  filter: invert(98%) sepia(0%) saturate(4%) hue-rotate(77deg) brightness(105%)
    contrast(100%);
  &:hover {
    filter: invert(98%) sepia(0%) saturate(4%) hue-rotate(77deg)
      brightness(105%) contrast(100%);
  }
  &:focus {
    filter: invert(98%) sepia(0%) saturate(4%) hue-rotate(77deg)
      brightness(105%) contrast(60%);
  }
}
.helpButtonContainer {
  width: $space-xl-s;
  height: $space-xl-s;
  &:hover {
    background-color: $blue-32;
  }
  &:focus {
    background-color: $blue-32;
  }
}
// Small device
.header.smallDeviceTopHeader {
  font-family: $base-font-family;
  background-color: $dark-color-sas-banner;
  justify-content: space-between !important;
  padding: $space-s-m;
}
.header.smallDevicePadding {
  padding: $space-s-m $space-s-m $space-s-m $space-m;
}
.smallDeviceBackToMySas {
  color: $white;
  border: 1px solid #0478ce;
  padding: $space-xs $space-s $space-xs $space-s;
  font-size: $text-m;
  border-radius: $border-radius-4;

  &:hover {
    color: $white-2;
  }
  &:focus {
    color: $white-1;
  }
}
